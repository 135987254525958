<template>
  <Dock class="shadow-lg" >
    <div class="text-center mt-2" style="min-height: 200px;">
      Rovit component render.
    </div>
  </Dock>
</template>
<script>
import { useDock } from '@/use/dock.js'

import { Dock } from '@rovit/dock'

export default {
  name: 'Rovit',
  metaInfo: {
    title: 'Rovit'
  },
  components: {
    Dock
  },
  setup(props, context) {
    // Dock Drag
    const { setTop } = useDock()
    function handleDockDrag({ movementY }) {
      setTop(movementY)
    }
    return {
      // Dock Drag
      handleDockDrag
    }
  }
}
</script>
